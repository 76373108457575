//
//
//
//
//
//
import StepAccount from '@/components/Auth/StepAccount';
export default {
  name: 'openRegistrationForm',
  components: {
    'app-step-account': StepAccount
  }
};