import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es7.object.entries.js";
//
//
//
//
//
//
//
//
//
//
import BannerImg from '@/components/Banners/Funrise/BannerImg';
import BannerHero from '@/components/Banners/BannerHero';
export default {
  props: {
    staticData: Array
  },
  components: {
    'app-banner-img': BannerImg,
    'app-banner-hero': BannerHero
  },
  data: function data() {
    return {
      slided: false
    };
  },
  computed: {
    swiperOption: function swiperOption() {
      return {
        allowTouchMove: this.filteredBanners.length > 1,
        observer: true,
        lazy: true,
        loadOnTransitionStart: true,
        loop: false,
        centeredSlides: true,
        speed: 900,
        slidesPerView: 1,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      };
    },
    filteredBanners: function filteredBanners() {
      var _this = this;

      if (this.banners == null) return [];
      return this.banners.filter(function (banner) {
        return !(banner.type === 'verification' && !_this.isShowVerificationPromo);
      }).filter(function (banner) {
        return !(banner.type === 'referral' && !_this.appMain.isShowReferralsInfo);
      });
    },
    banners: function banners() {
      return this.$store.getters['mainPage/bannerData'];
    },
    isShowReferralPage: function isShowReferralPage() {
      return this.$store.getters['referral/isShowReferralPage'];
    },
    appMain: function appMain() {
      return this.$store.getters['player/profile'];
    },
    loggedIn: function loggedIn() {
      return this.$store.getters['player/loggedIn'];
    },
    verificationStatus: function verificationStatus() {
      return this.$store.getters['player/verificationStatus'];
    },
    hasDeposits: function hasDeposits() {
      var _this$$store$getters$;

      return (_this$$store$getters$ = this.$store.getters['player/profile']) === null || _this$$store$getters$ === void 0 ? void 0 : _this$$store$getters$.hasDeposits;
    },
    bonuses: function bonuses() {
      return this.$store.getters['balance/bonuses'];
    },
    referralData: function referralData() {
      return this.$store.getters['player/referralData'];
    },
    bonusesLength: function bonusesLength() {
      if (this.bonuses) return Object.entries(this.bonuses).length;
      return 0;
    },
    priorityBonus: function priorityBonus() {
      return this.$store.getters['balance/priorityBonus'];
    },
    isShowImgBanner: function isShowImgBanner() {
      var _this$appMain, _this$appMain2;

      return !((_this$appMain = this.appMain) !== null && _this$appMain !== void 0 && _this$appMain.isGuest) && ((_this$appMain2 = this.appMain) === null || _this$appMain2 === void 0 ? void 0 : _this$appMain2.bannerPresetPackage);
    },
    isShowVerificationPromo: function isShowVerificationPromo() {
      var _this$appMain3;

      return this.loggedIn && ((_this$appMain3 = this.appMain) === null || _this$appMain3 === void 0 ? void 0 : _this$appMain3.isShowVerificationPromo) && this.verificationStatus !== 'approved' && this.verificationStatus !== 'rejected';
    }
  },
  methods: {
    onSlide: function onSlide() {
      if (!this.slided) this.slided = true;
      var realIndex = this.myswiper.realIndex;

      if (realIndex === 0) {
        this.myswiper.slideToLoop(0, 10, true);
      } else if (realIndex === 3) {
        this.myswiper.slideToLoop(realIndex, 10, true);
      }
    }
  },
  watch: {
    loggedIn: function loggedIn() {
      this.myswiper.init();
    }
  }
};