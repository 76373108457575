//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mockQuestBannerDataForGuest, mockQuestBannerDataForUser } from '@/components/Quest/mockData';
import Base from '@/mixins/Base';
import AppQuestBannerItem from '@/components/Quest/QuestBannerItem';
export default {
  name: 'QuestBanner',
  components: {
    'app-quest-banner-item': AppQuestBannerItem
  },
  mixins: [Base],
  computed: {
    loggedIn: function loggedIn() {
      return this.$store.getters['player/loggedIn'];
    },
    questData: function questData() {
      return this.loggedIn ? mockQuestBannerDataForUser : mockQuestBannerDataForGuest;
    },
    swiperOption: function swiperOption() {
      return {
        allowTouchMove: this.questData.questList.length > 5,
        loop: false,
        lazy: true,
        slidesPerView: 'auto',
        spaceBetween: 0,
        speed: 300,
        navigation: {
          nextEl: '#quest-banner-next',
          prevEl: '#quest-banner-prev'
        }
      };
    }
  },
  methods: {
    startPlaying: function startPlaying() {
      if (this.loggedIn) {
        this.$router.push({
          name: 'quest'
        });
      } else {
        this.$store.dispatch('modals/setAuthModal', {
          isOpen: true,
          activeTab: 'signup'
        });
      }
    }
  }
};