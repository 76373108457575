//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'AppQuestBannerItem',
  props: ['quest'],
  computed: {
    loggedIn: function loggedIn() {
      return this.$store.getters['player/loggedIn'];
    },
    progress: function progress() {
      if (this.quest.progress) {
        return {
          width: "".concat(100 / this.quest.progress.goal * this.quest.progress.complete, "%")
        };
      }

      return {
        width: '12px'
      };
    }
  }
};