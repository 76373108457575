import { render, staticRenderFns } from "./OpenRegistrationForm.vue?vue&type=template&id=6d1eae70&scoped=true&lang=pug&"
import script from "./OpenRegistrationForm.vue?vue&type=script&lang=js&"
export * from "./OpenRegistrationForm.vue?vue&type=script&lang=js&"
import style0 from "./OpenRegistrationForm.vue?vue&type=style&index=0&id=6d1eae70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d1eae70",
  null
  
)

export default component.exports