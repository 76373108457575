import "core-js/modules/es6.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import BannerPresetPackage from '@/mixins/BannerPresetPackage';
export default {
  name: 'BannerPresetPackageModal',
  mixins: [BannerPresetPackage],
  computed: {
    showBadge: function showBadge() {
      var _this$offer, _this$offer2;

      return ((_this$offer = this.offer) === null || _this$offer === void 0 ? void 0 : _this$offer.badgeLabel) && ((_this$offer2 = this.offer) === null || _this$offer2 === void 0 ? void 0 : _this$offer2.imageBadge);
    },
    badgeLabel: function badgeLabel() {
      var _this$offer3, _this$offer4;

      return (_this$offer3 = this.offer) !== null && _this$offer3 !== void 0 && _this$offer3.badgeLabel ? JSON.parse((_this$offer4 = this.offer) === null || _this$offer4 === void 0 ? void 0 : _this$offer4.badgeLabel) : '';
    },
    badgePercent: function badgePercent() {
      var _this$badgeLabel;

      return (_this$badgeLabel = this.badgeLabel) === null || _this$badgeLabel === void 0 ? void 0 : _this$badgeLabel.percent;
    },
    badgeText: function badgeText() {
      var _this$badgeLabel2;

      return (_this$badgeLabel2 = this.badgeLabel) === null || _this$badgeLabel2 === void 0 ? void 0 : _this$badgeLabel2.text;
    },
    usualPrice: function usualPrice() {
      var _this$offer5, _this$offer6;

      return (_this$offer5 = this.offer) !== null && _this$offer5 !== void 0 && _this$offer5.usualPrice ? Number((_this$offer6 = this.offer) === null || _this$offer6 === void 0 ? void 0 : _this$offer6.usualPrice).toFixed(2) : null;
    },
    isLoggedIn: function isLoggedIn() {
      return !this.$store.getters['player/profile'].isGuest;
    }
  },
  methods: {
    buyNow: function buyNow() {
      this.$store.gaBannerPresetPackage.click({
        buttonName: 'deposit',
        quantity: this.depositsCount,
        location: 'Your Personal Offer'
      });
      this.$store.gaCash.deposit({
        location: 'slider',
        type: '',
        step: 'view_payments_info'
      });
      this.openCash();
    }
  }
};