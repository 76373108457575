import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/var/www/piastercreek.com/piastercreek.com/funrize/node_modules/@babel/runtime-corejs2/helpers/defineProperty";
import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.regexp.replace.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.array.slice.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var ANIMATION_TIME_PARAMS = {
  1: {
    class: 'red',
    interval: 550,
    increment: 0.01,
    transition: 500
  },
  2: {
    class: 'silver',
    interval: 550,
    increment: 0.05,
    transition: 500
  },
  3: {
    class: 'gold',
    interval: 550,
    increment: 0.1,
    transition: 500
  },
  4: {
    class: 'diamond',
    interval: 550,
    increment: 0.15,
    transition: 500
  }
};
export default {
  name: 'app-jackpot-item',
  props: ['jackpot', 'staticText'],
  data: function data() {
    return {
      item: _objectSpread({}, this.jackpot),
      valueObj: {},
      timer: 0,
      interval: ANIMATION_TIME_PARAMS[this.jackpot.id].interval,
      incVal: ANIMATION_TIME_PARAMS[this.jackpot.id].increment,
      transition: ANIMATION_TIME_PARAMS[this.jackpot.id].transition,
      oldAmount: 0
    };
  },
  watch: {
    item: function item() {
      this.setIncVal();
      this.setOldAmount(this.getAmount);
    },
    jackpot: function jackpot(value) {
      this.item = _objectSpread({}, value);
      this.interval = ANIMATION_TIME_PARAMS[value.id].interval;
      this.incVal = ANIMATION_TIME_PARAMS[value.id].increment;
      this.transition = ANIMATION_TIME_PARAMS[value.id].transition;
      this.jackpotID = ANIMATION_TIME_PARAMS[value.id].class;
    }
  },
  computed: {
    isSweepStakes: function isSweepStakes() {
      return this.$store.getters['player/gameMode'] === 'SweepStakes';
    },
    getInterval: function getInterval() {
      return this.interval;
    },
    getIncVal: function getIncVal() {
      return this.incVal;
    },
    getAmount: function getAmount() {
      return Number(this.item.amount);
    },
    jackpotValue: function jackpotValue() {
      var factor = this.isSweepStakes ? 1 : 100;
      return this.getAmount * factor;
    }
  },
  methods: {
    formatNumber: function formatNumber(value, isFloat) {
      if (value == null) return '';
      var sum = Number(value).toString().replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1,');

      if (isFloat && !sum.includes('.')) {
        return "".concat(sum, ".00");
      }

      if (sum.length - 1 - sum.indexOf('.') === 1) {
        return "".concat(sum, "0");
      }

      return sum;
    },
    valueToArray: function valueToArray() {
      var intToStrArr = this.formatNumber(this.getAmount.toFixed(2), true).split('');
      var dotIndex = intToStrArr.indexOf('.');
      var fractional = intToStrArr.slice(dotIndex + 1);
      var integer = intToStrArr.slice(0, dotIndex);
      this.valueObj = {
        integer: integer,
        fractional: fractional
      };
    },
    incrementValue: function incrementValue() {
      var amount = this.getAmount;
      amount += this.getIncVal;
      this.item.amount = amount;
      this.valueToArray();
    },
    incrementValueInterval: function incrementValueInterval() {
      this.timer = window.setInterval(this.incrementValue, this.getInterval);
    },
    setOldAmount: function setOldAmount(value) {
      this.oldAmount = value;
      return this;
    },
    setIncVal: function setIncVal() {
      var val = (this.getAmount - this.oldAmount) / 20;

      if (val > 0) {
        this.incVal = val;
      }
    },
    setInterval: function setInterval(value) {
      this.interval = value;
    }
  },
  mounted: function mounted() {
    this.valueToArray(); // this.incrementValueInterval();
    // this.setOldAmount(this.getAmount);
  }
};