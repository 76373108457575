//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Comments',
  data: function data() {
    return {
      swiperOption: {
        loop: false,
        lazy: true,
        slidesPerView: 'auto',
        spaceBetween: 0,
        speed: 300
      },
      commentsList: [{
        name: this.$t('tao.comments.one.name'),
        location: this.$t('tao.comments.one.location'),
        text: this.$t('tao.comments.one.text')
      }, {
        name: this.$t('tao.comments.two.name'),
        location: this.$t('tao.comments.two.location'),
        text: this.$t('tao.comments.two.text')
      }, {
        name: this.$t('tao.comments.three.name'),
        location: this.$t('tao.comments.three.location'),
        text: this.$t('tao.comments.three.text')
      }, {
        name: this.$t('tao.comments.four.name'),
        location: this.$t('tao.comments.four.location'),
        text: this.$t('tao.comments.four.text')
      }]
    };
  }
};