import _toConsumableArray from "/var/www/piastercreek.com/piastercreek.com/funrize/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import '@/components/icons/play';
import { preFetchPage } from '@/helpers/router';
import Base from '@/mixins/Base';
import GameSlider from '@/components/Games/GameSlider';
import Banners from '@/components/Banners';
import ActivityFeed from '@/components/Activities/__ActivityFeedAsCards';
import Benefits from '@/components/Benefits/Benefits';
import PromotionsMain from '@/components/Promotions/PromotionsMain';
import QuestBanner from '@/components/Quest/QuestBanner';
import Comments from '@/components/Comments/Comments';
import Jackpot from '@/components/Jackpot/Jackpot';
import OpenRegistrationForm from '@/components/OpenRegistrationForm/OpenRegistrationForm';
export default {
  name: 'PageIndex',
  mixins: [Base],
  components: {
    'app-banners': Banners,
    'app-game-slider': GameSlider,
    'app-activity-feed': ActivityFeed,
    'app-benefits': Benefits,
    'app-quest-banner': QuestBanner,
    'app-comments': Comments,
    'app-jackpot': Jackpot,
    'app-open-registration-form': OpenRegistrationForm,
    'app-promotions-main': PromotionsMain
  },
  props: {
    config: Object
  },
  data: function data() {
    return {
      breakpointsLiveCasino: {
        639: {
          slidesPerView: 1,
          spaceBetween: 16
        },
        800: {
          slidesPerView: 2,
          spaceBetween: 24
        },
        1440: {
          slidesPerView: 5,
          spaceBetween: 24
        },
        1600: {
          slidesPerView: 5,
          spaceBetween: 24
        },
        1920: {
          slidesPerView: 5,
          spaceBetween: 32
        }
      }
    };
  },
  preFetch: preFetchPage,
  meta: function meta() {
    var _this$seoData, _this$seoData2, _this$seoData3;

    return {
      title: ((_this$seoData = this.seoData) === null || _this$seoData === void 0 ? void 0 : _this$seoData.seoTitle) || 'PiasterCreek',
      meta: {
        description: {
          name: 'description',
          content: (_this$seoData2 = this.seoData) === null || _this$seoData2 === void 0 ? void 0 : _this$seoData2.seoDescription
        },
        keywords: {
          name: 'keywords',
          content: ((_this$seoData3 = this.seoData) === null || _this$seoData3 === void 0 ? void 0 : _this$seoData3.seoKeywords) || ''
        }
      }
    };
  },
  computed: {
    gaRaceData: function gaRaceData() {
      return {
        location: this.loggedIn ? 'index' : 'race',
        formName: 'Race'
      };
    },
    lastGames: function lastGames() {
      return this.$store.getters['player/lastGames'];
    },
    seoData: function seoData() {
      return this.$store.getters['mainPage/seoData'];
    },
    games: function games() {
      return this.$store.getters['games/slots'];
    },
    loggedIn: function loggedIn() {
      return this.$store.getters['player/loggedIn'];
    },
    sections: function sections() {
      var _this = this;

      return this.$store.getters['sidebar/pageSections'].filter(function (section) {
        if (section.id === 'gettingHot') {
          return _this.gettingHot != null && _this.gettingHot.length > 0;
        }

        return true;
      });
    },
    sorterSections: function sorterSections() {
      var _this2 = this;

      return this.sections.filter(function (item) {
        var _this2$favorites, _this2$lastGames, _this2$topGames, _this2$holdAndLinkGam, _this2$jackpotGames, _this2$fruitGames, _this2$hotGames, _this2$fishingGames;

        if (_this2.loggedIn && item.onlyForGuest) return false;

        if (item.id === 'favorite' && !((_this2$favorites = _this2.favorites) !== null && _this2$favorites !== void 0 && _this2$favorites.length || (_this2$lastGames = _this2.lastGames) !== null && _this2$lastGames !== void 0 && _this2$lastGames.length)) {
          return false;
        }

        if (item.id === 'topGames' && !((_this2$topGames = _this2.topGames) !== null && _this2$topGames !== void 0 && _this2$topGames.length)) {
          return false;
        }

        if (item.id === 'holdAndLink' && !((_this2$holdAndLinkGam = _this2.holdAndLinkGames) !== null && _this2$holdAndLinkGam !== void 0 && _this2$holdAndLinkGam.length)) {
          return false;
        }

        if (item.id === 'jackpotGames' && !((_this2$jackpotGames = _this2.jackpotGames) !== null && _this2$jackpotGames !== void 0 && _this2$jackpotGames.length)) {
          return false;
        }

        if (item.id === 'fruitGames' && !((_this2$fruitGames = _this2.fruitGames) !== null && _this2$fruitGames !== void 0 && _this2$fruitGames.length)) {
          return false;
        }

        if (item.id === 'hotGames' && !((_this2$hotGames = _this2.hotGames) !== null && _this2$hotGames !== void 0 && _this2$hotGames.length)) {
          return false;
        }

        if (item.id === 'fishingGames' && !((_this2$fishingGames = _this2.fishingGames) !== null && _this2$fishingGames !== void 0 && _this2$fishingGames.length)) {
          return false;
        }

        if (item.id !== 'races') return true;
        return !_this2.isSweepStakes && Object.keys(_this2.race);
      });
    },
    bonuses: function bonuses() {
      return this.$store.getters['balance/bonuses'];
    },
    sectionsState: function sectionsState() {
      return {
        promotions: !!this.bonuses,
        gettingHot: this.gettingHot != null && this.gettingHot.length > 0
      };
    },
    screenWidth: function screenWidth() {
      return this.$q.screen.width;
    },
    gettingHot: function gettingHot() {
      return this.$store.getters['games/gettingHot'];
    },
    race: function race() {
      return this.$store.getters['tournament/tournament'];
    },
    gameMode: function gameMode() {
      return this.$store.getters['player/gameMode'];
    },
    isSweepStakes: function isSweepStakes() {
      return this.gameMode === 'SweepStakes';
    },
    appMain: function appMain() {
      return this.$store.getters['player/profile'];
    },
    favorites: function favorites() {
      return this.$store.getters['player/favoriteGames'];
    },
    gamesData: function gamesData() {
      return this.$store.getters['mainPage/gamesData'];
    },
    topGames: function topGames() {
      var _this$gamesData, _this$gamesData$issue, _this$gamesData$issue2;

      return ((_this$gamesData = this.gamesData) === null || _this$gamesData === void 0 ? void 0 : (_this$gamesData$issue = _this$gamesData.issues) === null || _this$gamesData$issue === void 0 ? void 0 : (_this$gamesData$issue2 = _this$gamesData$issue['popular-games']) === null || _this$gamesData$issue2 === void 0 ? void 0 : _this$gamesData$issue2.games) || [];
    },
    holdAndLinkGames: function holdAndLinkGames() {
      var _this$gamesData2, _this$gamesData2$issu, _this$gamesData2$issu2;

      return ((_this$gamesData2 = this.gamesData) === null || _this$gamesData2 === void 0 ? void 0 : (_this$gamesData2$issu = _this$gamesData2.issues) === null || _this$gamesData2$issu === void 0 ? void 0 : (_this$gamesData2$issu2 = _this$gamesData2$issu['hold-n-link']) === null || _this$gamesData2$issu2 === void 0 ? void 0 : _this$gamesData2$issu2.games) || [];
    },
    jackpotGames: function jackpotGames() {
      var _this$gamesData3, _this$gamesData3$issu, _this$gamesData3$issu2;

      return ((_this$gamesData3 = this.gamesData) === null || _this$gamesData3 === void 0 ? void 0 : (_this$gamesData3$issu = _this$gamesData3.issues) === null || _this$gamesData3$issu === void 0 ? void 0 : (_this$gamesData3$issu2 = _this$gamesData3$issu.jackpot) === null || _this$gamesData3$issu2 === void 0 ? void 0 : _this$gamesData3$issu2.games) || [];
    },
    fruitGames: function fruitGames() {
      var _this$gamesData4, _this$gamesData4$issu, _this$gamesData4$issu2;

      return ((_this$gamesData4 = this.gamesData) === null || _this$gamesData4 === void 0 ? void 0 : (_this$gamesData4$issu = _this$gamesData4.issues) === null || _this$gamesData4$issu === void 0 ? void 0 : (_this$gamesData4$issu2 = _this$gamesData4$issu.fruits) === null || _this$gamesData4$issu2 === void 0 ? void 0 : _this$gamesData4$issu2.games) || [];
    },
    fishingGames: function fishingGames() {
      var _this$gamesData5, _this$gamesData5$issu, _this$gamesData5$issu2;

      return ((_this$gamesData5 = this.gamesData) === null || _this$gamesData5 === void 0 ? void 0 : (_this$gamesData5$issu = _this$gamesData5.issues) === null || _this$gamesData5$issu === void 0 ? void 0 : (_this$gamesData5$issu2 = _this$gamesData5$issu.fishing) === null || _this$gamesData5$issu2 === void 0 ? void 0 : _this$gamesData5$issu2.games) || [];
    },
    hotGames: function hotGames() {
      var _this$gamesData6, _this$gamesData6$issu, _this$gamesData6$issu2;

      return ((_this$gamesData6 = this.gamesData) === null || _this$gamesData6 === void 0 ? void 0 : (_this$gamesData6$issu = _this$gamesData6.issues) === null || _this$gamesData6$issu === void 0 ? void 0 : (_this$gamesData6$issu2 = _this$gamesData6$issu['hot-games']) === null || _this$gamesData6$issu2 === void 0 ? void 0 : _this$gamesData6$issu2.games) || [];
    },
    getLastAndFavoritesGames: function getLastAndFavoritesGames() {
      var uniqArray = _toConsumableArray(new Set([].concat(_toConsumableArray(this.lastGames), _toConsumableArray(this.favorites))));

      return this.getGames(uniqArray);
    }
  },
  methods: {
    getGames: function getGames(arr) {
      var _this3 = this;

      var filtered = [];

      if (this.games && arr != null) {
        arr.forEach(function (game) {
          var item = _this3.games.find(function (g) {
            return g.id === game;
          });

          if (!item) return;
          filtered.push(item);
        });
        return filtered;
      }

      return filtered;
    }
  }
};