import { render, staticRenderFns } from "./Jackpot.vue?vue&type=template&id=6f7d269a&scoped=true&lang=pug&"
import script from "./Jackpot.vue?vue&type=script&lang=js&"
export * from "./Jackpot.vue?vue&type=script&lang=js&"
import style0 from "./Jackpot.vue?vue&type=style&index=0&id=6f7d269a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f7d269a",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QItem,QItemSection,QBtn});
