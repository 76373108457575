//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Benefits',
  data: function data() {
    return {
      pageData: [{
        icon: 'benefits-safe',
        title: this.$t('tao.benefits.blockOneTitle'),
        text: this.$t('tao.benefits.blockOneDesc')
      }, {
        icon: 'benefits-chat',
        title: this.$t('tao.benefits.blockTwoTitle'),
        text: this.$t('tao.benefits.blockTwoDesc')
      }, {
        icon: 'benefits-top',
        title: this.$t('tao.benefits.blockThreeTitle'),
        text: this.$t('tao.benefits.blockThreeDesc')
      }]
    };
  }
};