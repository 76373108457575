import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.regexp.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import StepAccount from '@/components/Auth/StepAccount';
export default {
  name: 'BannerHero',
  components: {
    'app-step-account': StepAccount
  },
  props: {
    banner: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    appMain: function appMain() {
      return this.$store.getters['player/profile'];
    },
    isLoggedIn: function isLoggedIn() {
      return !this.$store.getters['player/profile'].isGuest;
    },
    showDescription: function showDescription() {
      return this.banner.type === 'verification';
    },
    getButtonData: function getButtonData() {
      var _this = this;

      var buttonData = {
        click: {}
      };

      if (this.appMain.isGuest) {
        buttonData.click = this.openAuth;
      }

      if (this.banner.buttonLink) {
        buttonData.click = function () {
          if (_this.banner.buttonLink === '/register/') {
            // eslint-disable-next-line no-unused-expressions
            _this.$store.dispatch('modals/setAuthModal', {
              isOpen: true
            });

            return;
          }

          if (_this.banner.buttonLink.includes('facebook')) {
            window.open(_this.banner.buttonLink, '_blank');
            return;
          }

          if (_this.banner.buttonLink.includes('game=')) {
            var gameSlug = _this.banner.buttonLink.replace('game=', '');

            console.log('gameSlug', gameSlug);

            _this.openGame(gameSlug, true);

            return;
          } // eslint-disable-next-line vue/no-side-effects-in-computed-properties


          _this.$router.push(_this.banner.buttonLink);
        };
      }

      return buttonData;
    }
  },
  methods: {
    openGame: function openGame(gameSlug, isMoney) {
      var game = {
        id: gameSlug,
        isMoney: isMoney
      };
      this.$store.gaRegister.open({
        location: game === null || game === void 0 ? void 0 : game.id,
        buttonName: 'money_play',
        formName: 'banner'
      });
      this.$store.dispatch('game/getGame', game);
    },
    openAuth: function openAuth() {
      this.$store.dispatch('modals/setAuthModal', {
        isOpen: true
      });
    },
    openAuthModal: function openAuthModal(value) {
      if (value === 'signup') {
        this.$store.gaRegister.open({
          location: 'login',
          buttonName: 'registration'
        });
      }

      this.$store.dispatch('modals/setAuthModal', {
        isOpen: true,
        activeTab: value
      });
    }
  }
};