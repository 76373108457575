export default {
  namespaced: true,
  state: function state() {
    return {
      allActivity: []
    };
  },
  getters: {
    allActivity: function allActivity(state) {
      return state.allActivity;
    }
  },
  mutations: {
    setAllActivity: function setAllActivity(state, payload) {
      state.allActivity = payload;
    },
    updateAllActivity: function updateAllActivity(state, payload) {
      if (state.allActivity.length > 15) {
        state.allActivity.pop();
      }

      state.allActivity.unshift(payload);
    }
  },
  actions: {
    initPlayerActivity: function initPlayerActivity(_ref, payload) {
      var commit = _ref.commit;
      commit('setAllActivity', payload);
    },
    update: function update(_ref2, payload) {
      var commit = _ref2.commit;
      var type = payload.type;

      if (type === 'win' || type === 'bigwin' || type === 'jackpot' || type === 'daily-wheel-accrual') {
        commit('updateAllActivity', payload);
      }
    }
  }
};